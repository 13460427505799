import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import FullwidthPageTemplate from '../components/FullwidthPageTemplate'
import { HTMLContent } from '../components/Content'

const FullwidthPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <FullwidthPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        notice={post.frontmatter.notice}
      />
    </Layout>
  )
}

FullwidthPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FullwidthPage

export const fullwidthPageQuery = graphql`
  query FullwidthPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        notice {
          title
          message
        }
      }
    }
  }
`
